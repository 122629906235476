import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";

function HtmlToImage() {
    const [url, setUrl] = useState("");
    const [htmlContent, setHtmlContent] = useState("");

    const fetchHtmlContent = async () => {
        try {
            const response = await fetch(url);
            const text = await response.text();
            setHtmlContent(text);
        } catch (error) {
            console.error("Error fetching HTML content:", error);
        }
    };

    const handleCapture = async () => {
        const element = document.getElementById("html-content");
        if (element) {
            try {
                const canvas = await html2canvas(element, {
                    useCORS: true,
                    logging: true,
                });
                const dataUrl = canvas.toDataURL("image/jpeg");
                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = "screenshot.jpg";
                link.click();
                // Clear the HTML content after capturing the screenshot
                setHtmlContent("");
            } catch (error) {
                console.error("Error capturing screenshot:", error);
            }
        } else {
            console.warn("Element not found for capturing.");
        }
    };

    useEffect(() => {
        if (htmlContent) {
            handleCapture();
        }
    }, [htmlContent]);

    return (
        <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
            <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter URL"
                style={{ width: '80%', margin: '10px' }}
            />
            <button onClick={fetchHtmlContent}>Load Content</button>
            <div
                id="html-content"
                style={{ width: '100%', height: '100%', overflow: 'auto' }}
                dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
        </div>
    );
}

export default HtmlToImage; 