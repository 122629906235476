import React, { forwardRef } from 'react';
import './PlaceOrderWidget.scss';

const PlaceOrderWidget = forwardRef<HTMLDivElement, { meta: any, onAnotherIssue: () => void }>((props, ref) => {
    return (
        <div ref={ref} className="place-order-widget">
            <div dangerouslySetInnerHTML={{ __html: props.meta.html }} />
            <div className="button-group">
                <button className="primary-button">Place Order</button>
                <button className="secondary-button" onClick={props.onAnotherIssue}>
                    I've Another Issue
                </button>
            </div>
        </div>
    );
});

export default PlaceOrderWidget; 