import React from 'react';
import './SelectedReasonWidget.scss';

const SelectedReasonWidget = ({ meta }: { meta: any }) => {
    return (
        <div className="selected-reason-widget">
            <div dangerouslySetInnerHTML={{ __html: meta.html }} />
        </div>
    );
};

export default SelectedReasonWidget; 