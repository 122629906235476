import React, { forwardRef } from 'react';
import './ChipsListingWidget.scss';

const ChipsListingWidget = forwardRef<HTMLDivElement, { meta: any, onSelect: (chipId: string) => void }>((props, ref) => {
    return (
        <div ref={ref} className="chips-listing-widget">
            {props.meta.chips.map((chip: any) => (
                <button
                    key={chip.chip_id}
                    className="chip"
                    onClick={() => props.onSelect(chip.chip_id)}
                >
                    {chip.chip_name}
                </button>
            ))}
        </div>
    );
});

export default ChipsListingWidget; 