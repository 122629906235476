import React, { useState } from 'react';
import axios from 'axios';

const RecommendationComponent = () => {
    const [inputValue, setInputValue] = useState('');
    const [selectedType, setSelectedType] = useState('product');
    const [recommendations, setRecommendations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sourceProduct, setSourceProduct] = useState<any>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedType(e.target.value);
    };

    const renderSourceCategory = (categoryData: any) => {
        if (!categoryData) return null;

        return (
            <div style={{
                marginBottom: '20px',
                borderBottom: '2px solid #eee',
                paddingBottom: '20px'
            }}>
                <h2 style={{
                    fontSize: '16px',
                    marginBottom: '15px',
                    color: '#333'
                }}>Showing recommendations for:</h2>
                <div style={{
                    display: 'flex',
                    gap: '15px',
                    padding: '10px 0'
                }}>
                    <div style={{
                        minWidth: '200px',
                        maxWidth: '200px'
                    }}>
                        <div style={{
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '10px',
                            backgroundColor: 'white',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            height: '100%'
                        }}>
                            <img
                                src={`https://cdn.moglix.com/${categoryData.imageUrl}`}
                                alt={categoryData.categoryName}
                                style={{
                                    width: '100%',
                                    height: '150px',
                                    objectFit: 'cover',
                                    borderRadius: '4px'
                                }}
                            />
                            <h3 style={{
                                margin: '8px 0',
                                fontSize: '14px',
                                color: '#333',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>{categoryData.categoryName}</h3>
                            <p style={{
                                fontSize: '12px',
                                color: '#666',
                                margin: '4px 0'
                            }}>
                                Products: {categoryData.productCount}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderCard = (item: any) => {
        if (selectedType === 'category') {
            const categoryDetails = item.details;
            return (
                <div key={item.item_id} style={{
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    padding: '10px',
                    backgroundColor: 'white',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    height: '100%'
                }}>
                    <img
                        src={`https://cdn.moglix.com/${categoryDetails.imageUrl}`}
                        alt={categoryDetails.categoryName}
                        style={{
                            width: '100%',
                            height: '150px',
                            objectFit: 'cover',
                            borderRadius: '4px'
                        }}
                    />
                    <h3 style={{
                        margin: '8px 0',
                        fontSize: '14px',
                        color: '#333',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>{categoryDetails.categoryName}</h3>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '4px'
                    }}>
                        <p style={{
                            fontSize: '12px',
                            color: '#666',
                            margin: '4px 0'
                        }}>
                            Products: {categoryDetails.productCount}
                        </p>
                        <span style={{
                            fontSize: '12px',
                            color: '#007bff',
                            fontWeight: 'bold'
                        }}>
                            {(item.similarity_score * 100).toFixed(1)}% match
                        </span>
                    </div>
                </div>
            );
        }

        // Product card
        const productDetails = item.details;
        return (
            <div key={item.item_id} style={{
                border: '1px solid #ddd',
                borderRadius: '8px',
                padding: '10px',
                backgroundColor: 'white',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                height: '100%'
            }}>
                <img
                    src={`https://cdn.moglix.com/${productDetails.mainImageLink}`}
                    alt={productDetails.productName}
                    style={{
                        width: '100%',
                        height: '150px',
                        objectFit: 'cover',
                        borderRadius: '4px'
                    }}
                />
                <h3 style={{
                    margin: '8px 0',
                    fontSize: '14px',
                    color: '#333',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}>{productDetails.productName}</h3>
                <div style={{
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                }}>
                    <span style={{ fontWeight: 'bold', color: '#333' }}>
                        ₹{productDetails.salesPrice}
                    </span>
                    {productDetails.discount > 0 && (
                        <>
                            <span style={{
                                textDecoration: 'line-through',
                                color: '#666'
                            }}>
                                ₹{productDetails.mrp}
                            </span>
                            <span style={{ color: 'green' }}>
                                {productDetails.discount}% off
                            </span>
                        </>
                    )}
                </div>
                {productDetails.avgRating > 0 && (
                    <div style={{
                        fontSize: '12px',
                        color: '#666',
                        marginTop: '4px'
                    }}>
                        Rating: {productDetails.avgRating.toFixed(1)} ({productDetails.ratingCount})
                    </div>
                )}
                {item.similarity_score && <div style={{
                    fontSize: '12px',
                    color: '#007bff',
                    fontWeight: 'bold',
                    marginTop: '4px',
                    textAlign: 'right'
                }}>
                    {(item.similarity_score * 100).toFixed(1)}% match
                </div>}
            </div>
        );
    };

    const renderSourceProduct = (productData: any) => {
        if (!productData) return null;

        return (
            <div style={{
                marginBottom: '20px',
                borderBottom: '2px solid #eee',
                paddingBottom: '20px'
            }}>
                <h2 style={{
                    fontSize: '16px',
                    marginBottom: '15px',
                    color: '#333'
                }}>Showing recommendations for:</h2>
                <div style={{
                    display: 'flex',
                    gap: '15px',
                    padding: '10px 0'
                }}>
                    <div style={{
                        minWidth: '200px',
                        maxWidth: '200px'
                    }}>
                        {renderCard({
                            details: productData,
                            item_id: productData.moglixPartNumber
                        })}
                    </div>
                </div>
            </div>
        );
    };

    const handleSearch = async () => {
        if (!inputValue.trim()) {
            setRecommendations([]);
            setSourceProduct(null);
            return;
        }

        setIsLoading(true);
        const url = selectedType === 'product'
            ? `https://recommenderapi-uat.moglix.com/api/recommendations/product/${inputValue.toUpperCase()}`
            : `https://recommenderapi-uat.moglix.com/api/recommendations/category/${inputValue.toUpperCase()}`;

        try {
            const response = await axios.get(url);
            if (selectedType === 'product') {
                const sourceProduct = response.data.message.item_details;
                const recommendations = response.data.message.recommendations;
                setSourceProduct(sourceProduct);
                setRecommendations(recommendations);
            } else {
                const sourceCategory = response.data.message.item_details;
                const recommendations = response.data.message.recommendations;
                setSourceProduct(sourceCategory);
                setRecommendations(recommendations);
            }
        } catch (error) {
            console.error('Error fetching recommendations:', error);
            setRecommendations([]);
            setSourceProduct(null);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div style={{
            padding: '20px',
            maxWidth: '1200px',
            margin: '0 auto'
        }}>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Enter product or category ID"
                    style={{
                        padding: '10px 15px',
                        fontSize: '16px',
                        borderRadius: '8px',
                        border: '1px solid #ddd',
                        width: '300px'
                    }}
                />
                <select
                    value={selectedType}
                    onChange={handleTypeChange}
                    style={{
                        padding: '10px 15px',
                        fontSize: '16px',
                        borderRadius: '8px',
                        border: '1px solid #ddd',
                        backgroundColor: 'white'
                    }}
                >
                    <option value="product">Product</option>
                    <option value="category">Category</option>
                </select>
                <button
                    onClick={handleSearch}
                    disabled={isLoading}
                    style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        borderRadius: '8px',
                        border: 'none',
                        backgroundColor: '#007bff',
                        color: 'white',
                        cursor: 'pointer',
                        opacity: isLoading ? 0.7 : 1
                    }}
                >
                    {isLoading ? 'Searching...' : 'Search'}
                </button>
            </div>

            {/* Source section */}
            {sourceProduct && (
                selectedType === 'product'
                    ? renderSourceProduct(sourceProduct)
                    : renderSourceCategory(sourceProduct)
            )}

            {/* Recommendations section */}
            {recommendations?.length > 0 && (
                <div style={{ marginBottom: '20px' }}>
                    <h2 style={{
                        fontSize: '16px',
                        marginBottom: '15px',
                        color: '#333'
                    }}>
                        {selectedType === 'product' ? 'Similar Products' : 'Related Categories'}:
                    </h2>
                    <div style={{
                        display: 'flex',
                        overflowX: 'auto',
                        gap: '15px',
                        padding: '10px 0'
                    }}>
                        {recommendations?.map((item: any) => (
                            <div key={item.item_id} style={{
                                minWidth: '200px',
                                maxWidth: '200px',
                                flex: '0 0 auto'
                            }}>
                                {renderCard(item)}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RecommendationComponent; 