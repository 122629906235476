import React, { forwardRef } from 'react';
import './TextWidget.scss';

const TextWidget = forwardRef<HTMLDivElement, { meta: any }>((props, ref) => {
    return (
        <div ref={ref} className="text-widget">
            <div dangerouslySetInnerHTML={{ __html: props.meta.html }} />
        </div>
    );
});

export default TextWidget; 