import React, { useState, forwardRef } from 'react';
import './SelectAddressWidget.scss';

interface Address {
    idAddress: number;
    addressCustomerName: string;
    addressLine: string;
    city: string;
    state: { name: string };
    postCode: string;
    phone: string;
}

const SelectAddressWidget = forwardRef<HTMLDivElement, { meta: any, widgetId: string, handleMessage: (widgetId: string, addressId: any) => void }>((props, ref) => {
    const [selectedAddressId, setSelectedAddressId] = useState<number | null>(null);

    const handleAddressSelect = (id: number) => {
        setSelectedAddressId(id);
        props.handleMessage(props.widgetId, id);
    };

    const handleAddNewAddress = () => {
        console.log("Add new address clicked");
    };

    return (
        <div ref={ref} className="select-address-widget">
            {props.meta?.address_details?.addressList.map((address: Address) => (
                <div key={address.idAddress} className="address-card">
                    <input
                        type="radio"
                        name="address"
                        value={address.idAddress}
                        checked={selectedAddressId === address.idAddress}
                        onChange={() => handleAddressSelect(address.idAddress)}
                    />
                    <div className="address-info">
                        <strong>{address.addressCustomerName}</strong>
                        <p>{address.addressLine}</p>
                        <p>{address.city}, {address.state.name}, {address.postCode}</p>
                        <p>Mobile: {address.phone}</p>
                    </div>
                </div>
            ))}
            <button className="add-new-address-button" onClick={handleAddNewAddress}>
                ADD NEW ADDRESS
            </button>
        </div>
    );
});

export default SelectAddressWidget; 