import React, { forwardRef } from 'react';
import './ApplicableCouponListingWidget.scss';

const ApplicableCouponListingWidget = forwardRef<HTMLDivElement, { meta: any }>((props, ref) => {
    return (
        <div ref={ref} className="applicable-coupon-listing-widget">
            <h2>Great news!</h2>
            <p>I found {props.meta?.coupons?.data?.available.length} coupons that might be applicable to your order.</p>
            <div className="coupon-list">
                {props.meta?.coupons?.data?.available.map((coupon: any, index: number) => (
                    <div key={index} className="coupon-card">
                        <div className="coupon-info">
                            <p>{coupon.promoDescription}</p>
                        </div>
                        <div className="coupon-code">
                            <span>{coupon.promoCode}</span>
                            <span className="tap-to-copy">Tap to Copy</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default ApplicableCouponListingWidget; 