import React, { forwardRef } from 'react';
import './SimilarProductsWidget.scss';

const SimilarProductsWidget = forwardRef<HTMLDivElement, { meta: any }>((props, ref) => {
    return (
        <div ref={ref} className="similar-products-widget">
            {props.meta?.similar_products?.products.map((product: any, index: number) => (
                <div key={index} className="product-card">
                    <img src={`https://cdn.moglix.com/${product.mainImageLink}`} alt={product.name} className="product-image" />
                    <div className="product-info">
                        <h3>{product.productName}</h3>
                        <p>₹{product.salesPrice}</p>
                        <p>MRP ₹{product.mrp}</p>
                        <p>{product.discount}% OFF</p>
                    </div>
                </div>
            ))}
        </div>
    );
});

export default SimilarProductsWidget; 