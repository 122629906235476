import React, { forwardRef } from 'react';
import './CouponWidget.scss';

const CouponWidget = forwardRef<HTMLDivElement, { meta: any }>((props, ref) => {
    return (
        <div ref={ref} className="coupon-widget">
            <div className="coupon-details">
                {/* <img src={props.meta.dealImage} alt="Deal" className="deal-image" /> */}
                <div className="coupon-info">
                    <p>Get this at <strong>₹{props.meta.price || 750}</strong></p>
                    <p>Extra <strong>₹{props.meta.discount || 50} OFF</strong> with coupon*</p>
                </div>
                <div className="coupon-code">
                    <span>{props.meta.couponCode}</span>
                    <span className="copied-text">Copied</span>
                </div>
            </div>
            <p className="expiry-text">Hurry up, coupon will expire in 24hrs.</p>
        </div>
    );
});

export default CouponWidget; 