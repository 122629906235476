import React, { useState, forwardRef } from 'react';
import './CartItemsWidget.scss';

const CartItemsWidget = forwardRef<HTMLDivElement, { meta: any, widgetId: string, onSelectProduct: (widgetId: string, productId: string) => void }>((props, ref) => {
    const [selectedProductId, setSelectedProductId] = useState<string | null>(null);

    const handleProductSelect = (productId: string) => {
        setSelectedProductId(productId);
        props.onSelectProduct(props.widgetId, productId);
    };


    return (
        <div ref={ref} className="cart-items-widget">
            {props.meta?.cart_items?.itemsList?.map((item: any, index: number) => (
                <div key={index} className="item-card">
                    <input
                        type="radio"
                        name="product"
                        value={item.productId}
                        checked={selectedProductId === item.productId}
                        onChange={() => handleProductSelect(item.productId)}
                    />
                    <img src={item.productImg} alt={item.name} className="item-image" />
                    <div className="item-info">
                        <h3>{item.productName}</h3>
                        <p>₹{item.productUnitPrice}</p>
                        <p>Quantity: {item.productQuantity}</p>
                    </div>
                </div>
            ))}
        </div>
    );
});

export default CartItemsWidget; 