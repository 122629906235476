import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { apis } from "../../../core/services/params";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import EyeLine from "remixicon-react/EyeLineIcon";
import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import CommonPagination from "../../components/CommonPagination/CommonPagination";
import { DEFAULT_PAGE_SIZE } from "../../../utils/constants";

interface BookingMetadata {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    data: any[];
}

enum InternalBookingStatus {
    CUSTOMER_CONNECTED = "CUSTOMER_CONNECTED",
    QUOTATION_SHARED = "QUOTATION_SHARED",
    LEAD_CONFIRMED = "LEAD_CONFIRMED",
    VEHICLE_ALIGNED = "VEHICLE_ALIGNED",
    IN_TRANSIT = "IN_TRANSIT",
    DELIVERED = "DELIVERED"
}

enum BookingStatus {
    NEW = "NEW",
    IN_TRANSIT = "IN_TRANSIT",
    DELIVERED = "DELIVERED",
    CANCELLED = "CANCELLED"
}

const BOOKING_STATUS = [BookingStatus.NEW, BookingStatus.IN_TRANSIT, BookingStatus.DELIVERED, BookingStatus.CANCELLED]

const Bookings = () => {
    const { user } = useSelector((s: any) => s.authStore)
    const currentPage = useRef(1);

    const [bookings, setBookings] = useState<BookingMetadata>({
        totalCount: 0,
        pageSize: 0,
        currentPage: 0,
        totalPage: 0,
        data: [],
    });
    const [dateFilter, setDateFilter] = useState({
        startDate: '',
        endDate: ''
    });
    const [downloadDumpLoader, setDownloadDumpLoader] = useState(false)
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        searchBookings()
    }, []);

    const onPageChange = (_pageNumber: number) => {
        currentPage.current = _pageNumber
        searchBookings()
    }

    const searchBookings = async () => {
        setLoader(true)
        try {
            const response = await axios.post(apis.GET_BOOKINGS + `?page=${currentPage.current}`, {});
            const metadata = response?.data?.data?.metadata || {};
            const content = response?.data?.data?.content || [];
            setBookings({
                totalCount: metadata.totalElements || 0,
                pageSize: metadata.pageSize || 0,
                currentPage: metadata.currentPage || 0,
                totalPage: metadata.totalPages || 0,
                data: content
            });
        } catch (err) {
            console.error("Error fetching bookings:", err);
        }
        setLoader(false)
    };

    const updateBookingStatus = async (bookingId: string, status: string, vehicleType: string) => {
        if (status === "Select") {
            return;
        }

        const payload: any = {
            bookingId,
            status,
            updatedBy: user.id || -1,
            remarks: null,
            internalStatus: null
        };

        if (vehicleType === "HEAVY_TRUCK") {
            payload.internalStatus = status;
            payload.status = null;

            // Add quotation amount prompt for QUOTATION_SHARED status
            if (status === InternalBookingStatus.QUOTATION_SHARED) {
                const quotedAmount = window.prompt("Please enter the quoted amount*");
                if (!quotedAmount || isNaN(Number(quotedAmount))) {
                    toast.error("Please enter a valid quoted amount");
                    return;
                }
                payload.quotedAmount = Number(quotedAmount);
            }
        }

        const confirmUpdate = window.confirm(`Are you sure you want to update with bookingId **${bookingId}** with status **${status}**?`);
        if (!confirmUpdate) {
            return;
        }

        if (status === BookingStatus.CANCELLED.toString()) {
            const remarks = window.prompt("Remarks to cancel booking*");
            if (!remarks) {
                toast.error("Since no remarks were entered, the booking update could not be completed.")
                return;
            }
            payload.remarks = remarks;
        }

        try {
            setLoader(true);
            const response = await axios.post(apis.UPDATE_BOOKING_STATUS, payload);
            if (response.data.statusCode === 200) {
                await searchBookings();
            }
            toast.success("Updated successfully")
        } catch (error) {
            console.error("Error updating booking status:", error);
        } finally {
            setLoader(false);
        }
    };

    function getNextPossibleBookingStatuses(currentStatus: BookingStatus | InternalBookingStatus, vehicleType: string) {
        if ([BookingStatus.CANCELLED, BookingStatus.DELIVERED, InternalBookingStatus.DELIVERED].includes(currentStatus as any)) return [];

        if (vehicleType === "HEAVY_TRUCK") {
            // Return all internal statuses
            return Object.values(InternalBookingStatus);
        }

        // Return all regular booking statuses
        return BOOKING_STATUS;
    }

    function navigateToBookingDetailsPage(bookingDetails: any) {
        localStorage.setItem(bookingDetails.bookingId, JSON.stringify(bookingDetails));
        window.open(`/mexpress/booking-details/${bookingDetails.bookingId}`, '_blank');
    }

    function updateTimeStamp(e: any, variable: string) {
        const date = e.target.value;
        const timeStamp = new Date(date).getTime();
        const obj: any = dateFilter;
        obj[variable] = timeStamp;
        setDateFilter(obj)
    }

    async function downloadDumpHandler() {
        setDownloadDumpLoader(true)
        try {
            if (dateFilter.startDate && dateFilter.endDate) {
                const startDate = moment(dateFilter.startDate).format("YYYY-MM-DD");
                const endDate = moment(dateFilter.endDate).format("YYYY-MM-DD");

                if (startDate && endDate) {
                    const response = await axios.post(
                        `${apis.GET_BOOKINGS}?startDate=${startDate}&endDate=${endDate}`,
                        {}
                    );
                    const dataSets = response?.data?.data?.content || [];

                    if (dataSets.length > 0) {
                        const transformedData = dataSets.map((item: any) => ({
                            bookingId: item.bookingId,
                            vehicleType: item.vehicleType,
                            senderName: item.shippingDetails?.senderName,
                            senderAddress: item.shippingDetails?.senderAddress,
                            senderPincode: item.shippingDetails?.senderPincode,
                            senderPhone: item.shippingDetails?.senderPhone,
                            receiverName: item.shippingDetails?.receiverName,
                            receiverPhone: item.shippingDetails?.receiverPhone,
                            receiverAddress: item.shippingDetails?.receiverAddress,
                            receiverPincode: item.shippingDetails?.receiverPincode,
                            pickupDate: item.shippingDetails?.pickupDate,
                            pickupTime: item.shippingDetails?.pickupTime,
                            instructions: item.shippingDetails?.instructions,
                            status: item.status,
                            internalStatus: item.internalStatus,
                            fare: item.fare,
                            distance: item.distance,
                            estimateTime: item.estimateTime,
                            remarks: item.remarks,
                            material: item.material,
                            weight: item.weight,
                            truckType: item.truckType,
                            noOfTrucks: item.noOfTrucks,
                            loadType: item.loadType,
                            cancellationReason: item.cancellationReason,
                            additionalComments: item.additionalComments
                        }));
                        // Define the sheet data
                        const worksheet = XLSX.utils.json_to_sheet(transformedData);
                        const workbook = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(workbook, worksheet, "Bookings");

                        // Create a Blob and trigger download
                        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
                        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
                        saveAs(blob, `Bookings_${startDate}_to_${endDate}.xlsx`);

                        toast.success("File downloaded successfully!");
                    } else {
                        toast.error("No data found for the selected date range.");
                    }
                }
            }
        } catch (err) {
            console.error(err);
            toast.error("Something went wrong!");
        }
        setDownloadDumpLoader(false)
    }

    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container" >
                        {/* DATE RANGE FILTER */}
                        <div>
                            <p className="text-xs mb-1.5 text-[#5E5D5D] font-medium pt-4 px-4">Select Date Range</p>
                            <div className="flex w-full pb-4">
                                <div className="px-4">
                                    <input
                                        className="w-full h-7 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                        type="date"
                                        onChange={(e) => updateTimeStamp(e, 'startDate')}
                                    />
                                </div>
                                <div className="px-4 pl-0">
                                    <input
                                        className="w-full h-7 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                        type="date"
                                        onChange={(e) => updateTimeStamp(e, 'endDate')}
                                    />
                                </div>
                                <button disabled={downloadDumpLoader} onClick={downloadDumpHandler} className="h-7 px-6 text-xs bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-1.5">{downloadDumpLoader ? "Downloading..." : "Download Dump"}</button>
                            </div>
                        </div>
                        <div className="mt-2.5 campaign-list bg-white mb-[54px]">
                            <table className="w-full">
                                <thead className="bg-[#3c3c3c]">
                                    <tr>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Booking ID</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Sender Name & Address</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Pickup Date & Time</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]"> Recipent Name & Address</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Vehicle Choice</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Fare</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Quoted Amount</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Distance (km)</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Booked At</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">View Details</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff]">Remarks</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff] w-[120px]">Status</th>
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-[#ffffff] w-[100px]">Action</th>
                                    </tr>
                                </thead>

                                {loader ? <Loader /> : <tbody>
                                    {bookings.data.map((item: any, index: any) => (
                                        <tr className="align-top">
                                            <td className="px-4 py-3 text-left text-xs text-[#086AC9]">{item.bookingId}</td>
                                            <td className="px-4 py-3 text-left text-xs text-[#6F6F6F]">
                                                <p className="text-[#086AC9] text-xs">{item.shippingDetails.senderName} {item.shippingDetails.senderPhone}</p>
                                                <p className="text-[10px] ">{item.shippingDetails.senderAddress}</p>
                                            </td>
                                            <td className="px-4 py-3 text-left text-xs text-[#6F6F6F]">
                                                <p className="text-[#3C3C3C]">{item.shippingDetails.pickupDate}</p>
                                                <p className="text-[10px] ">{item.shippingDetails.pickupTime}</p>
                                            </td>
                                            <td className="px-4 py-3 text-left text-xs text-[#6F6F6F]">
                                                <p className="text-[#086AC9] text-xs">{item.shippingDetails.receiverName} {item.shippingDetails.receiverPhone}</p>
                                                <p className="text-[10px] ">{item.shippingDetails.receiverAddress}</p>
                                            </td>
                                            <td className="px-4 py-3 text-left text-xs text-[#3C3C3C]">{item.vehicleType}</td>
                                            <td className="px-4 py-3 text-left text-xs text-[#3C3C3C]">{item.fare.toFixed(2)}</td>
                                            <td className="px-4 py-3 text-left text-xs text-[#3C3C3C]">{item?.quotedAmount?.toFixed(2) || "N/A"}</td>
                                            <td className="px-4 py-3 text-left text-xs text-[#3C3C3C]">{item.distance.toFixed(2)}</td>
                                            <td className="px-4 py-3 text-left text-[10px] text-[#3C3C3C]">
                                                {item.shippingDetails.createdAt}
                                            </td>
                                            <td className="px-4 py-3 text-left text-xs text-[#3C3C3C]">
                                                <a
                                                    href={`/mexpress/booking-details/${item.bookingId}`}
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent default navigation to handle custom logic
                                                        navigateToBookingDetailsPage(item);
                                                    }}
                                                    target="_blank" // Opens in a new tab
                                                    rel="noopener noreferrer" // Security measure
                                                    className="text-[#3C3C3C] no-underline flex items-center" // Styling for link behavior
                                                >
                                                    <EyeLine className="w-5 h-5" /> {/* Eye icon from remixicon-react */}
                                                </a>
                                            </td>
                                            <td className="px-4 py-3 text-left text-xs text-[#3C3C3C]">{item.remarks || item.cancellationReason}</td>
                                            <td className="px-4 py-3 text-sm w-[120px]">
                                                <span className={BOOKING_STATUS_COLOR_CODE_MAP[item.internalStatus || item.status]}>
                                                    {item.internalStatus || item.status}
                                                </span>
                                            </td>
                                            <td className="px-4 py-3 text-sm w-[100px]">
                                                <select
                                                    className="bg-white border rounded px-2 py-1 text-sm w-full"
                                                    onChange={(e) => updateBookingStatus(item.bookingId, e.target.value, item.vehicleType)}
                                                >
                                                    <option>Select</option>
                                                    {getNextPossibleBookingStatuses(item.status || item.internalStatus, item.vehicleType).map((status) => (
                                                        <option key={status} value={status}>
                                                            {status}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>}
                            </table>
                        </div>

                        {bookings.data && <CommonPagination data={bookings} PAGE_SIZE={DEFAULT_PAGE_SIZE} onPageChange={onPageChange} />}
                    </div>
                </div>
            </div>
        </>
    )

}

const BOOKING_STATUS_COLOR_CODE_MAP: any = {
    NEW: "border border-[#1E90FF] text-[#1E90FF] px-1 py-0.5 rounded",       // Blue for new
    IN_TRANSIT: "border border-[#FFA500] text-[#FFA500] px-1 py-0.5 rounded", // Orange for in transit
    DELIVERED: "border border-[#32CD32] text-[#32CD32] px-1 py-0.5 rounded",  // Green for delivered
    CANCELLED: "border border-[#D9232D] text-[#D9232D] px-1 py-0.5 rounded",   // Red for cancelled
    CUSTOMER_CONNECTED: "border border-[#1E90FF] text-[#1E90FF] px-1 py-0.5 rounded",
    QUOTATION_SHARED: "border border-[#9932CC] text-[#9932CC] px-1 py-0.5 rounded",
    LEAD_CONFIRMED: "border border-[#FF8C00] text-[#FF8C00] px-1 py-0.5 rounded",
    VEHICLE_ALIGNED: "border border-[#20B2AA] text-[#20B2AA] px-1 py-0.5 rounded"
};

export default Bookings;