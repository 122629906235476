import { useState } from "react"
import { AI_POWERED_TYPES } from "../../../utils/constants";
import SideNavbar from "../../components/Navbar/SideNavbar";
import Header from "../../components/Header/Header";
import AiPoweredHeader from "../../components/Header/AiPoweredHeader";
import GenerateProductDescription from "../../components/AiPowered/GenerateProductDesc";
import RecommendationComponent from "../../components/AiPowered/RecommendationComponent";

const AiPowered = () => {
    const [aiPoweredType, setAiPoweredType] = useState("")

    return (<>
        <div className="flex mainWrapper w-full">
            <div className="sidenav-wrapper">
                <SideNavbar />
            </div>
            <div className="right-container">
                <div className="header-container">
                    <Header />
                </div>
                <div className="body-container">
                    <AiPoweredHeader setAiPoweredType={setAiPoweredType}
                        aiPoweredTypes={Object.keys(AI_POWERED_TYPES)}/>

                    {/* GENERATE_PRODUCT_DESCRIPTION*/}
                    {aiPoweredType === AI_POWERED_TYPES.GENERATE_PRODUCT_DESCRIPTION && <GenerateProductDescription />}
                    {/* ADDITIONAL_COMPONENT */}
                    {aiPoweredType === AI_POWERED_TYPES.RECOMMENDATION && <RecommendationComponent />}
                </div>
            </div>
        </div>
    </>)
}


export default AiPowered;