import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import TextWidget from './TextWidget';
import ChipsListingWidget from './ChipsListingWidget';
import SelectedReasonWidget from './SelectedReasonWidget';
import PlaceOrderWidget from './PlaceOrderWidget';
import SimilarProductsWidget from './SimilarProductsWidget'
import CartItemsWidget from './CartItemsWidget';
import CouponWidget from './CouponWidget'
import ApplicableCouponListingWidget from './ApplicableCouponListingWidget';
import SelectAddressWidget from './SelectAddressWidget';

const BASE_URI = 'https://ai-utility.moglilabs.com'
// const BASE_URI = 'http://localhost:5000'

const Chatbot = () => {
    const [widgets, setWidgets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userInputsMap, setUserInputsMap] = useState<{ [key: string]: string }>({});
    const chatContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Initial API call to start chat
        const startChat = async () => {
            setLoading(true);
            try {
                const response = await axios.post(`${BASE_URI}/api/chatbot-assistant/start-chat`, {
                    userId: '',
                    session_id: 'session_id_103',
                    device: 'pwa',
                    build_version: '1.0.0'
                });
                const chatSessionId = response.data.data.chat_session_id;
                localStorage.setItem('chat_session_id', chatSessionId);
                setWidgets(response.data.data.widgets_to_show);
            } catch (error) {
                console.error('Error starting chat:', error);
            } finally {
                setLoading(false);
            }
        };

        startChat();
    }, []);

    useEffect(() => {
        const scrollToBottom = () => {
            if (chatContainerRef.current) {
                setTimeout(() => {
                    chatContainerRef.current!.scrollTop = chatContainerRef.current!.scrollHeight;
                }, 0);
            }
        };

        scrollToBottom();
    }, [widgets]);

    const handleUserInputChange = (widgetId: string, value: string) => {
        setUserInputsMap((prevInputs) => ({
            ...prevInputs,
            [widgetId]: value,
        }));
    };

    const handleSendMessage = async (widgetId: string, productId?: string, addressId?: string) => {
        setLoading(true);
        try {
            const chatSessionId = localStorage.getItem('chat_session_id');
            const response = await axios.post(`${BASE_URI}/api/chatbot-assistant/send-message`, {
                chat_session_id: chatSessionId,
                session_id: 'session_id_103',
                user_id: '1257777254',
                pincode: '110092',
                widget_id: widgetId,
                selected_reason_id: widgetId,
                product_id: productId || "",
                address_id: addressId || "",
                user_inputs_map: userInputsMap, // Use the input from the map
               // is_user_logged_in: false  
            });
            setWidgets(response.data.data.previous_widgets);
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleUserInputSubmit = (widgetId: string) => {
        const userInput = userInputsMap[widgetId] || '';
        if (userInput.trim() === '') {
            alert('Please enter a valid input');
            return; // Do not call the API if the input is empty
        }
        handleSendMessage(widgetId);
    };

    const handleProductSelect = (widgetId: string, productId: string) => {
        handleSendMessage(widgetId, productId);
    };

    const handleAddressSelect = (widgetId: string, addressId: string) => {
        handleSendMessage(widgetId, '', addressId);
    }

    const handleAnotherIssue = () => {
        handleSendMessage('I_HAVE_ANOTHER_ISSUE');
    };

    return (
        <div className="chatbot-container" ref={chatContainerRef}>
            <h1 className="chatbot-title">Moglix Assistant</h1>
            {loading && <div className="loader">Chatbot is typing...</div>}
            {widgets.map((widget: any, index: number) => {
                if (widget.widget === 'TEXT_WIDGET' && widget.meta.user_prompt && widget.meta.form_type === 'input') {
                    return (
                        <div key={index} className="input-widget">
                            <p>{widget.meta.text}</p>
                            <input
                                type="text"
                                value={userInputsMap[widget.widget_id] || ''}
                                onChange={(e) => handleUserInputChange(widget.widget_id, e.target.value)}
                                placeholder="Enter your response"
                                required
                            />
                            <button onClick={() => handleUserInputSubmit(widget.widget_id)}>Submit</button>
                        </div>
                    );
                }

                switch (widget.widget) {
                    case 'TEXT_WIDGET':
                        return <TextWidget key={index} meta={widget.meta} />;
                    case 'CHIPS_LISTING_WIDGET':
                        return <ChipsListingWidget key={index} meta={widget.meta} onSelect={handleSendMessage} />;
                    case 'SELECTED_REASON_WIDGET':
                        return <SelectedReasonWidget key={index} meta={widget.meta} />;
                    case 'PLACE_ORDER_WIDGET':
                        return <PlaceOrderWidget key={index} meta={widget.meta} onAnotherIssue={handleAnotherIssue} />;
                    case 'SIMILAR_PRODUCTS_WIDGET':
                        return <SimilarProductsWidget key={index} meta={widget.meta} />;
                    case 'CART_ITEMS_WIDGET':
                        return <CartItemsWidget key={index} meta={widget.meta} widgetId={widget.widget_id} onSelectProduct={handleProductSelect} />;
                    case 'COUPON_WIDGET':
                        return <CouponWidget key={index} meta={widget.meta} />;
                    case 'APPLICABLE_COUPON_LISTING_WIDGET':
                        return <ApplicableCouponListingWidget key={index} meta={widget.meta} />;
                    case 'SELECT_ADDRESS_WIDGET':
                        return <SelectAddressWidget key={index} widgetId={widget.widget_id} meta={widget.meta} handleMessage={handleAddressSelect} />;
                    default:
                        return null;
                }
            })}
        </div>
    );
};

export default Chatbot; 