import React, { useState, useEffect } from 'react';
import './Test.css';

function Test() {
    const [widgets, setWidgets] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [viewContent, setViewContent] = useState(null);
    const [editContent, setEditContent] = useState(null);
    const [editText, setEditText] = useState('');
    const [chips, setChips] = useState([]);

    const fetchWidgets = async () => {
        try {
            const response = await fetch('https://ai-utility.moglilabs.com/api/chatbot-assistant/fetch-widgets');
            const data = await response.json();
            setWidgets(data.data);
        } catch (error) {
            console.error('Error fetching widgets:', error);
        }
    };

    const fetchChips = async () => {
        try {
            const response = await fetch('https://ai-utility.moglilabs.com/api/chatbot-assistant/get-chips');
            const data = await response.json();
            setChips(data.chips);
        } catch (error) {
            console.error('Error fetching chips:', error);
        }
    };

    useEffect(() => {
        fetchWidgets();
        fetchChips();
    }, []);

    const uniqueWidgetTypes = [...new Set(widgets.map(widget => widget.widget))];

    const handleTypeClick = (type) => {
        setSelectedType(type);
    };

    const handleIconClick = (widget) => {
        setViewContent(widget);
        setEditContent(null);
        setShowModal(true);
    };

    const handleEditClick = (widget) => {
        setEditContent(widget);
        setEditText(JSON.stringify(widget, null, 2));
        setViewContent(null);
        setShowModal(true);
    };

    const handleEditTextChange = (e) => {
        setEditText(e.target.value);
    };

    const handleUpdate = async () => {
        try {
            const updatedContent = JSON.parse(editText);
            delete updatedContent._id
            setEditContent(updatedContent);

            const response = await fetch('https://ai-utility.moglilabs.com/api/chatbot-assistant/update-widget', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedContent),
            });

            if (response.ok) {
                alert('Widget updated successfully');
                setShowModal(false);
                setEditContent(null);
                fetchWidgets();
            } else {
                alert('Failed to update widget');
            }
        } catch (error) {
            alert('Invalid JSON format');
            console.error('Error updating widget:', error);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setViewContent(null);
        setEditContent(null);
    };

    return (
        <div className="test-container">
            <h1>Chatbot Widgets</h1>
            <div className="chips-container">
                {chips.map(chip => (
                    <div key={chip.chip_id} className="chip-card">
                        <strong>{chip.chip_name}</strong>
                        <p>ID: {chip.chip_id} - {chip.menu_type}</p>
                    </div>
                ))}
            </div>
            <div className="widget-types">
                {uniqueWidgetTypes.map(type => (
                    <div key={type} className="widget-type" onClick={() => handleTypeClick(type)}>
                        {type}
                    </div>
                ))}
            </div>
            <div className="widgets">
                {selectedType && widgets.filter(widget => widget.widget === selectedType).map(widget => (
                    <div key={widget._id} className="widget">
                        <h3>Widget ID: {widget.widget_id}</h3>
                        {/* <button className="view-json-btn" onClick={() => handleIconClick(widget)}>View JSON</button> */}
                        <button className="edit-json-btn" onClick={() => handleEditClick(widget)}>Edit JSON</button>
                        {widget.widget === 'CHIPS_LISTING_WIDGET' ? (
                            <div className="chips">
                                {widget.meta.chips.map(chip => (
                                    <div key={chip.id} className="chip">
                                        {chip.text}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <>
                                <div>
                                    <strong>HTML Preview:</strong>
                                    <div dangerouslySetInnerHTML={{ __html: widget.meta.html || 'No HTML content' }} />
                                </div>
                                <div>
                                    <strong>Text:</strong>
                                    <p>{widget.meta.text || 'No text content'}</p>
                                </div>
                                <div>
                                    <strong>Data:</strong>
                                    <pre>{JSON.stringify(widget.meta.data, null, 2) || 'No data available'}</pre>
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        {viewContent && (
                            <>
                                <h2>View Widget JSON Data</h2>
                                <pre>{JSON.stringify(viewContent, null, 2)}</pre>
                            </>
                        )}
                        {editContent && (
                            <>
                                <h2>Edit Widget JSON Data</h2>
                                <textarea
                                    value={editText}
                                    onChange={handleEditTextChange}
                                    rows="10"
                                    cols="50"
                                />
                                <button onClick={handleUpdate}>Update</button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Test;
